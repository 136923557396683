import { memo, useCallback, useState, useEffect, useRef } from "react";
import {
  Center,
  Spinner,
  Stack,
  Th,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { TemplateDetailModal } from "../../organisms/modal/TemplateDetailModal";
import { SendTemplateModal } from "../../organisms/modal/SendTemplateModal";
import { useMailTemplate } from "../../../hooks/useMailTemplates";
import { MailTemplate } from "../../../types/api/mailTemplate";
import { AddButton } from "../../atoms/button/AddButton";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { MailTemplateRow } from "../../organisms/datarow/MailTemplateRow";
import { TableContent } from "../../organisms/layout/TableContent";
import { useLoginUser } from "../../../hooks/useLoginUser";

export const EmailTemplates = memo(() => {
  const dlTemplate = useDisclosure();
  const dlSendTemp = useDisclosure();
  const {
    getMailTemplates,
    mailTemplateAdd,
    mailTemplateEdit,
    mailTemplateDelete,
    sendTemplateMail,
    loading,
    mailTemplates,
  } = useMailTemplate();

  const newTemplate: MailTemplate = {
    TemplateName: "",
    SubjectPart: "",
    TextPart: "",
    HtmlPart: "",
  };

  const { selectTenant } = useLoginUser();

  const [selectedTemplate, setSelectedTemplate] =
    useState<MailTemplate>(newTemplate);
  const didLogRef = useRef(false);
  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      getMailTemplates();
    }
  }, []);

  const onClickTemplate = useCallback(
    (template_name: string) => {
      //      onSelectType({ typeName, types, dlType.onOpen });
      const targetTemplate = mailTemplates.find(
        (obj) => obj.TemplateName === template_name
      );
      if (targetTemplate) {
        setSelectedTemplate(targetTemplate);
        dlTemplate.onOpen();
      } else {
        setSelectedTemplate(newTemplate);
        dlTemplate.onOpen();
      }
    },
    [mailTemplates, newTemplate, dlTemplate]
  );

  const onSendTemplate = useCallback(
    (template_name: string) => {
      //      onSelectType({ typeName, types, dlType.onOpen });
      const targetTemplate = mailTemplates.find(
        (obj) => obj.TemplateName === template_name
      );
      if (targetTemplate) {
        setSelectedTemplate(targetTemplate);
        dlSendTemp.onOpen();
      } else {
        setSelectedTemplate(newTemplate);
        dlSendTemp.onOpen();
      }
    },
    [mailTemplates, newTemplate, dlSendTemp]
  );

  return (
    <Stack>
      <PageDiscription>メールテンプレートを選択してください。</PageDiscription>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.500" />
        </Center>
      ) : (
        <>
          <AddButton onClick={() => onClickTemplate("")} />
          <TableContent
            heightOffset="50px"
            headerRows={
              <Tr key="header">
                <Th>テンプレート名</Th>
                <Th>件名</Th>
                <Th width="100%">テキスト形式</Th>
                <Th>テスト送信</Th>
                {/* <Th>HTML形式</Th> */}
              </Tr>
            }
            bodyRows={mailTemplates.map((obj) => (
              <MailTemplateRow
                key={obj.TemplateName}
                templateName={obj.TemplateName}
                subjectPart={obj.SubjectPart}
                htmlPart={obj.HtmlPart}
                textPart={obj.TextPart}
                onClick={onClickTemplate}
                onClickSend={onSendTemplate}
              />
            ))}
          ></TableContent>
        </>
      )}
      <TemplateDetailModal
        isOpen={dlTemplate.isOpen}
        onClose={dlTemplate.onClose}
        mailTemplate={selectedTemplate}
        tenant={selectTenant}
        onDelete={mailTemplateDelete}
        onInsert={mailTemplateAdd}
        onUpdate={mailTemplateEdit}
      />
      <SendTemplateModal
        isOpen={dlSendTemp.isOpen}
        onClose={dlSendTemp.onClose}
        mailTemplate={selectedTemplate}
        onSendMail={sendTemplateMail}
      />
    </Stack>
  );
});
